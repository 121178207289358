
@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/mixins.scss';

.swiper-slide {
  .sgh-category-module-item {
    &--images {
      margin-bottom: 1.2rem;
      @screen lgr {
        width: 15.8rem;
      }
    }
    .badgesale {
      transform: translateY(-4.5rem);
    }
  }
}
.sgh-category-module-item {
  position: relative;
  text-align: center;

  &--images {
    max-width: 24.29rem;
    max-height: 24.22rem;
    background-color: $alabaster;
    overflow: hidden;
    display: block;
    position: relative;

    ::v-deep img {
      width: 100%;
    }

    .hover {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0);
      transition: all 1s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
    @screen smr {
      max-width: 16.7rem;
      max-height: 16.7rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1025px) {
      max-width: 19rem;
      max-height: 18.8rem;
    }
  }

  button {
    margin-top: 1.2rem;
  }

  .badgesale {
    min-width: 4.5rem;
    max-width: 19rem;
    height: 2rem;
    transform: translateY(-3rem);

    .wrap-badge {
      box-shadow: 1rem 0 0 var(--badge-color-back);
      border-radius: 5.3rem;
      margin-right: 1rem;

      span {
        font-weight: 600;
        border-radius: 5.3rem;
        background-color: var(--badge-color-top);
        padding: 0.2rem 0.3rem 0 0.6rem;
        box-shadow: 0.5rem 0 0 var(--badge-color-mid);
      }
    }
  }
}
