
@import '../../../../styles/variables.scss';
@import '../../../../styles/fonts.scss';

.sgh-category-module {
  @screen lg {
    height: 42.5rem;
  }

  ::v-deep .swiper-slide {
    flex-shrink: 0;
    margin-right: 0rem;
    margin-left: 0rem;
    transition: margin-left linear 0.2s;
    width: 15.8rem !important;
    @screen lg {
      margin-right: 0.5rem !important;
      width: 15.517rem !important;
    }
    @media (min-width: 1220px) {
      margin-right: 0.669rem !important;
      width: 20.299rem !important;
    }
    @screen xl {
      margin-right: 0.8rem !important;
      width: 24.289rem !important;
    }
  }
  ::v-deep .swiper-button {
    display: none;
    &-disabled {
      display: none !important;
    }
    &-prev,
    &-next {
      display: none;
      width: 4.5rem;
      height: 4.5rem;
      background-color: $white;
      opacity: 0.8;
      &:hover {
        background-color: $white;
        opacity: 1;
      }
      &:after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    display: flex;
    width: 27.4rem;
    margin: auto;
    margin-top: 4rem;
    @screen md {
      display: none;
    }
  }
  ::v-deep .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin-top: 3.2rem;
      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
      }
      &:last-child {
        border-radius: 0 0.5rem 0.5rem 0;
      }
      width: 100%;
      margin: 0;
      height: 0.5rem;
      background-color: $mine-shaft !important;
      opacity: 0.1;
      border-radius: 0;
      border: none;
      &-active {
        background-color: $mine-shaft !important;
        opacity: 1;
        border-radius: 0.5rem;
      }
    }
  }

  position: relative;

  &--container {
    z-index: 1;
  }

  &--image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &--text {
    .pre-title {
      font-size: 1.2rem;
      letter-spacing: normal;
      text-transform: uppercase;
      font-weight: normal;
      line-height: 1.4rem;
      margin-bottom: 0.2rem;

      @screen lgr {
        line-height: 1.5;
        margin: 0;
        letter-spacing: 0.03rem;
      }
    }

    .title {
      @include actaHeadlineFontTitle();
      margin-bottom: 0.2rem;

      @screen lgr {
        margin: 0;
      }
    }

    .text {
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1.7rem;

      @screen lgr {
        font-size: 1.6rem;
        line-height: 1.5;
      }

      p {
        @screen lgr {
          padding: 0;
          margin: 0;
          font-size: 1.6rem;
          font-weight: normal;
          line-height: 1.5;
        }
      }
    }

    @screen lgr {
      padding-bottom: 3.2rem;
    }

    @screen lg {
      width: 25.483rem;
    }

    @media (min-width: 1220px) {
      width: 30.331rem;
    }

    @screen xl {
      width: 35.8rem;
    }

    .primary-2023,
    .ghost-2023 {
      width: fit-content;
    }
  }

  &--items {
    gap: 0.8rem;
    overflow: hidden;

    @screen lgr {
      row-gap: 1.6rem;
      column-gap: 0.9rem;
      width: 100%;
    }
  }

  &--carousel-items {
    width: 103.6rem;
    &:hover {
      ::v-deep .swiper-button {
        &-prev,
        &-next {
          display: flex;
          align-items: center;
          @screen mdr {
            display: none;
          }
        }
        &-prev {
          transform: rotate(180deg);
          left: 0;
        }
        &-next {
          right: 0;
        }
      }
    }
  }

  .text {
    max-width: 31.8rem;
  }
}
