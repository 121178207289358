
@import '../../../styles/variables';

.skeleton {
  display: none;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
    &:nth-child(even) {
      padding-left: 0.953rem;
    }
    &:nth-child(odd) {
      padding-right: 0.953rem;
    }
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      display: flex;
    }
    &:nth-of-type(-n+2) {
      padding-bottom: 3rem;
    }

    @screen lg {
      padding-left: 1.44rem;
      padding-right: 1.44rem;
      display: flex;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      &:nth-of-type(-n+6) {
        padding-bottom: 4rem;
      }
    }

  &-img {
    display: flex;
    background-color: $white-smoke;
    height: 9rem;
    width: 18rem;
  }
  &-text {
    display: flex;
    width: 50%;
    margin: 0.8rem 0 0.4rem;
    height: 1.9rem;
    background-color: $white-smoke;
    &:nth-child(3) {
      width: 40%;
      margin: 0 0 0.4rem;
    }
  }
  &-cta {
    height: 1.5rem;
    width: 8rem;
    background-color: $white-smoke;
    border-radius: 0;
  }
  &-title {
    margin-bottom: 2.5rem;
    display: flex;
    width: 20rem;
    height: 1.4rem;
    top: 0;
    position: absolute;
    background-color: $white-smoke;
  }
}

.cms-wall-slider {
  ::v-deep .swiper-pagination {
    display: flex;
    width: 23.8rem;
    margin: 4rem auto 0;
    @screen md {
      display: none;
    }
  }
  ::v-deep .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin-top: 3.2rem;
      &:first-child {
        border-radius: 0.2rem 0 0 0.2rem;
      }
      &:last-child {
        border-radius: 0 0.2rem 0.2rem 0;
      }
      width: 100%;
      margin: 0;
      height: 0.2rem;
      background-color: $mine-shaft !important;
      opacity: 0.1;
      border-radius: 0;
      border: none;
      &-active {
        background-color: $mine-shaft !important;
        opacity: 1;
        border-radius: 0.2rem;
      }
    }
  }

  ::v-deep .swiper-button {
    &-prev {
      left: 0;
      transform: rotate(180deg);
    }
    &-prev,
    &-next {
      width: 4rem;
      height: 4rem;
      top: auto;
      margin-top: -3.2rem;
      &:after {
        content: '';
      }
      .common__icon--carousel-arrow {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
