
@import '../../../styles/variables';
@import '../../../styles/fonts';

.full-width {
  content-visibility: auto;
  contain-intrinsic-size: auto none;
 .skeleton{
    .full-width--video-controller{
      top: calc(100% - 15.3rem);
    }
  }
  &--container {
    display: flex;
    flex-direction: column;
  }
  &--images {
    position: relative;
    width: 100%;
  
    &-skeleton {
      background-color: $white-smoke;
    }
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (min-width: 1920px) {
      aspect-ratio: 9/2;
    }

    &-l {
      aspect-ratio: 47/50;
      height: auto;
    }

    &-m {
      aspect-ratio: 117/100;
      height: auto;
    }

    &-s {
      aspect-ratio: 39/25;
      height: auto;
    }

    @screen lg {
      &-l {
        aspect-ratio: 144/59
      }

      &-m {
        aspect-ratio: 72/23;
      }

      &-s {
        aspect-ratio: 9/2;
      }
    }
  }
  &--text {
    margin-bottom: 1.6rem;
    max-width: 93rem;
    padding: 1.6rem 4rem 0 4rem;
    width: 100%;
    word-break: break-word;

    @screen mdr {
      margin-bottom: 2.4rem;
      padding: 1.6rem 1.6rem 0 ;
    }

    & > * {
      margin: 0;
    }

    &-title {
      @include actaHeadlineFontTitle();
      margin-bottom: 0.4rem;
    }

    &-subtitle {
      font-size: 1.4rem;
      line-height: 1.5;
      margin-bottom: 1.6rem;
    }

    &-button {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.02rem;
      height: auto;
    }

    @screen lg {
      &-subtitle {
        line-height: normal;
      }
    }

    button:not(:last-child) {
      margin-right: 4rem;
    }
    &.text-left-max-width {
      align-self: flex-start;
    }
    &.text-center-max-width {
      align-self: center;
    }
    &.text-right-max-width {
      align-self: flex-end;
    }
  }

  &--video-controller {
    display: flex;
    position: absolute;
    z-index: 50;
    top: 40.8rem;
    transform: translateY(-100%);
    gap: 1.6rem;
    transition: linear 0.2;

    span {
      width: 4rem;
      height: 4rem;
    }

    &.left {
      left: 4rem;
    }

    &.right {
      right: 4rem;
    }
    @screen lg {
    &.video-controller-top{
        &-s,
        &-m,
        &-l{
          top: calc(100% - 2.4rem)
        }


       
      }

   
    }
    @screen lgr {
      top: calc(100% - 2.4rem);

      &.left {
        left: 1.6rem;
      }

      &.right {
        right: 1.6rem;
      }
    }
  }
}
